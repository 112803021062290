// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: normal;
	src: url('/assets/fonts/montserrat/montserrat-v11-latin-regular.ttf');
}
@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/montserrat-v11-latin-300.ttf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/montserrat-v11-latin-500.ttf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/assets/fonts/montserrat/montserrat-v11-latin-700.ttf');
	font-weight: 700;
	font-style: normal;
}

body {
  position: relative; font-family: 'Montserrat', Arial, sans-serif;
  padding-top: 0 !important; --padding-top: 0 !important;
  margin-top: 0 !important; --margin-top: 0 !important;
}
@media (min-width: 992px){ body { font-size: 16px;}}
@media (max-width: 991px){ body { font-size: 16px;}}
@media (max-width: 575px){ body { font-size: 14px;}}
@media (max-width: 360px){ body { font-size: 12px;}}

.main-section-body-upload-context-content { height: 12em; padding-top: 6em; text-align: center; color: #354651; font-size: 1em;}
.main-section-body-upload-context-button { margin-top: 1.5em; border: 0; text-decoration: underline; background: transparent none; outline: 0;}
.main-section-body-card-upload { border-radius: 15px }
.main-section-body-card-upload-content{ display: flex; flex-direction: column; gap: 10px; justify-content: center; align-items: center; }
.ng-drag-drop-container{ width: 75%; height: 40vh; border-radius: 15px; }

#android-safe-area-inset-top-darth { position: fixed; left: 0; top: 0; width: 100%; background: #33beaa; opacity: 0.8; z-index: 200; display: none;}
#android-safe-area-inset-top-darth.android { height: var(--notch-inset-top);}
#android-safe-area-inset-top-darth.ios { height: max(env(safe-area-inset-top), 20px);}
#android-safe-area-inset-top-darth.show { display: block;}

.padding-top-safe-android { padding-top: 25px !important; --padding-top: 25px !important;}
.padding-top-safe-ios { padding-top: max(env(safe-area-inset-top), 20px) !important; --padding-top: max(env(safe-area-inset-top), 20px) !important;}

/* ARTICLE */

  @media (min-width: 992px){ .main-section-article { background-color: #fff; padding: 0 2em 2em 2em;}}
  @media (max-width: 991px){
    .main-section-article { flex-grow: 1; padding: 0 0 2em 0; background-color: #fff; overflow: hidden; border-top-left-radius: 2em; border-top-right-radius: 2em;}
    .main-section-article.video { position: relative; padding-bottom: 0;}
  }
  .main-section-article-context { color: #354651;}
  .main-section-article-context.gray { margin-top: 2em; padding: 1em 0 2em 0; background-color: #E6E8EA;}
  .main-section-article-context p.intro { margin: 1em 0 0 0; padding: 0 20px; font-size: 1.2em; font-weight: 700; line-height: 1.3em; display: block;}
  .main-section-article-context h1 { margin: 1em 0 0 0; padding: 0 20px; font-size: 2em; line-height: 1.2em; font-weight: 700; color: #EB8329; display: block;}
  .main-section-article-context h2 { margin: 1em 0 0 0; padding: 0 20px; font-size: 1.5em; line-height: 1.2em; font-weight: 700; display: block;}
  .main-section-article-context h3 { margin: 1em 0 0 0; padding: 0 20px; font-size: 1.3em; line-height: 1.2em; font-weight: 700; display: block;}
  .main-section-article-context h4 { margin: 1em 0 0 0; padding: 0 20px; font-size: 1em; line-height: 1.2em; font-weight: 700; display: block;}
  .main-section-article-context h5,
  .main-section-article-context h6,
  .main-section-article-context p { margin: 1em 0 0 0; padding: 0 20px; font-size: 1em; line-height: 1.3em; display: block;}
  .main-section-article-context img { margin: 1em 0 0 0; padding: 0 20px; width: 100%; height: auto; display: block;}
  @media (min-width: 992px){ .main-section-article-context img:first-child { margin: 0;}}
  @media (max-width: 991px){ .main-section-article-context img:first-child { margin: 0; padding: 0;}}
  .main-section-article-context ul,
  .main-section-article-context ol { margin: 1em 0 0 0; padding: 0 20px; counter-reset: repas; display: block;}
  .main-section-article-context li { position: relative; margin: .5em 0 0 0; font-size: 1em; line-height: 1.3em; display: block;}
  .main-section-article-context ul li { padding: 0 0 0 20px; background: url(/assets/img/bullet.png) no-repeat left top .4em;}
  .main-section-article-context ul ul li { padding: 0 0 0 20px; background: none;}
  .main-section-article-context ul ul li:before { 
    position: absolute; left: 0; top: 0; content: "-"; width: 20px; color: #eb8328; font-weight: 500; display: block;}
  .main-section-article-context ol li { margin: .5em 0 0 0; padding: 0 0 0 20px; list-style-type: none; counter-increment: repas;}
  .main-section-article-context ul ul,
  .main-section-article-context ul ol,
  .main-section-article-context ol ul,
  .main-section-article-context ol ol { margin: 0; padding: 0;}
  .main-section-article-context ol li:before { 
    position: absolute; left: 0; top: 0; content: counter(repas) "."; width: 20px; color: #eb8328; font-weight: 500; display: block;}
  .main-section-article-poster { position: relative; width: 100%; height: 300px; margin-bottom: calc(32px + 2em);}
  .main-section-article-poster .play { 
    position: absolute; right: 2em; bottom: -32px; box-shadow: 0 4px 20px 0px rgba(0,0,0,0.3); cursor: pointer;
    width: 64px; height: 64px; background: #fff url(/assets/img/play-video.png) no-repeat center center; border-radius: 50%;}

  #player { 
    position: fixed; left: 0; top: 0; width: 100%; height: 100%;  z-index: 111;
    display: flex; flex-direction: column; justify-content: center; align-items: center;}
  #player .wrapper { 
    position: relative; width: 100%; max-width: 600px; height: 100%; margin: 0 auto;
    display: flex; flex-direction: column; justify-content: center; align-items: center;
  }
  @media (min-width: 992px){ #player .wrapper { max-width: 800px;}}
  #player .player-video { position: relative; width: 100%; height: 0; float: none; clear: both; margin: 0; padding-bottom: 56.25%; padding-top: 25px;}
  #player .close { position: absolute; top: -70px; right: 1em; width: 50px; height: 50px; 
    background: #eb8328 url(/assets/img/close.png) no-repeat center center; border-radius: 50%; cursor: pointer;}
    #player .player-video iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%;}

.smartbanner-show { margin-top: 0px !important;}
.error-toast { --background: rgba(239, 77, 67, 0.79); --color: white; margin-top: 20px; }
ion-toast { --button-color: white; --ion-color-step-100: white; }
.toast-button-cancel { color: var(--ion-color-step-100, #e6e6e6); }